<template>
  <div class="w-100">
    <b-overlay :show="loading" rounded="sm">
    <validation-observer ref="observer">
      <div class="w-100 mt-1">
        <validation-provider #default="validationContext" :name="$t('message.Price')" rules="required|numeric" class="w-100">
          <label for="price">{{$t('message.Price')}}</label>
          <b-form-input v-model="payment.price"></b-form-input>
          <span class="text-danger">{{validationContext.errors[0]}}</span>
        </validation-provider>
      </div>
      <div class="w-100 mt-1">
        <validation-provider #default="validationContext" :name="$t('message.Currency')" rules="required" class="w-100">
          <label for="currency">{{$t('message.Currency')}}</label>
          <v-select :options="currencies" label="name" v-model="paymentCurrency"></v-select>
          <span class="text-danger">{{validationContext.errors[0]}}</span>
        </validation-provider>
      </div>
      <div class="w-100 mt-1">
        <validation-provider #default="validationContext" :name="$t('data.payment_type')" rules="required" class="w-100">
          <label for="currency">{{$t('data.payment_type')}}</label>
          <v-select :options="paymentTypes" label="name_ru" v-model="paymentType"></v-select>
          <span class="text-danger">{{validationContext.errors[0]}}</span>
        </validation-provider>
      </div>
      <div class="w-100 mt-4 d-flex justify-content-end">
        <b-button variant="primary" @click="addPayment" :disabled="disabled">{{$t('message.Add')}}</b-button>
      </div>
    </validation-observer>
    </b-overlay>
  </div>
</template>

<script>
  import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
  import { fetchContractItem, getPaymentTypes } from '@/api/payment-types/payment-types'
  import vSelect from 'vue-select'
  import * as rules from 'vee-validate/dist/rules';
  import { messages } from 'vee-validate/dist/locale/ru.json';
  import { contractPayment } from '@/api/payments/contracts'

  Object.keys(rules).forEach(rule => {
    extend(rule, {
      ...rules[rule],
      message: messages[rule]
    });
  });

export default {
  name: 'ContractPayment',
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props:{
    contractId: {
      default: null,
    }
  },
  data() {
    return {
      payment: {
        contract_id: null,
        price: '',
        currency_id: null,
        payment_type_id: null
      },
      paymentTypes: [],
      paymentCurrency: null,
      paymentType: null,
      loading: false,
      disabled: false,
    }
  },
  mounted() {
    getPaymentTypes().then(res => {
      this.paymentTypes = res.data.data;
    });
    this.$store.dispatch('settings/fetchCurrencies');
  },
  computed: {
    currencies() {
      return this.$store.state.settings.currencies;
    }
  },
  methods: {
    addPayment() {
      const isValid = this.$refs.observer.validate();
      this.disabled = true;
        setTimeout(() => {
          this.disabled = false;
        }, 1000)
      if(isValid) {
        this.payment.currency_id = this.paymentCurrency.id;
        this.payment.payment_type_id = this.paymentType.id;
        contractPayment(this.payment).then(res => {
          this.$emit('closeModal')
        })
      }
    },
    getContractItem(val) {
      this.loading = true
      fetchContractItem({service_id: val.service_id, service_item_id: val.service_item_id}).then(res => {
        this.payment.currency_id = res.data.data.currency;
        this.paymentType = res.data.data.payment_type
        this.loading = false;
      })
    }
  },
  watch: {
    contractId: {
      immediate: true,
      handler(val) {
        this.getContractItem(val);
        this.payment.contract_id = val.id;
        this.payment.price = parseInt(val.market_price);
        this.paymentCurrency = val.currency
      }
    }
  }
}
</script>

<style scoped>

</style>
