import request from '../../libs/axios'

export function getContracts(params) {
  return request({
    url:'/api/contracts',
    method:'get',
    params,
  })
}

export function contractPayment(data) {
  return request({
    url: '/api/payments',
    method: 'post',
    data,
  })
}

export function changeContractStatus(data) {
  return request({
    url: '/api/change-contract-status',
    method:'post',
    data,
  })
}
