import req from '../../libs/axios'

export function getCountractStatus(params) {
  return req({
    url: '/api/contract-statuses',
    method: 'get',
    params
  })
}

export function showCountractStatus(id) {
  return req({
    url: `/api/contract-statuses/${id}`,
    method: 'get'
  })
}

export function addCountractStatus(data) {
  return req({
    url: '/api/contract-statuses',
    method: 'post',
    data
  })
}

export function updateCountractStatus(data) {
  return req({
    url: `/api/contract-statuses/${data.id}`,
    method: 'put',
    data
  })
}

export function deleteCountractStatus(id) {
  return req({
    url: `/api/contract-statuses/${id}`,
    method: 'delete'
  })
}