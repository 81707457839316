<template>
  <div class="container-fluid">
    <b-overlay :show="loading">

    </b-overlay>
    <ValidationObserver ref="refFormObserver">
      <div>
        <ValidationProvider #default="validationContext" :name="$t('message.Penalty_price')" rules="required">
          <div class="wrapper">
            <p class="wrapper-title">
              {{$t('message.Penalty_price')}}
            </p>
            <div class="wrapper-form">
              <b-icon icon="wallet2"></b-icon>
              <b-form-input v-model="penaltyPrice" type="number"></b-form-input>
            </div>
            <span class="text-danger">
              {{ validationContext.errors[0] }}
            </span>
          </div>
        </ValidationProvider>

        <div class="wrapper">
          <p class="wrapper-title">
            {{$t('message.Title')}}
          </p>
          <div class="wrapper-form">
            <b-icon icon="journal"></b-icon>
            <textarea v-model="comment" cols="30" rows="2"></textarea>
          </div>
        </div>

        <div class="w-100 d-flex justify-content-end">
          <b-button
            variant="warning"
            @click="handleClose"
          >
            {{$t('message.back')}}
          </b-button>

          <b-button
            class="ml-1"
            variant="primary"
            :disabled="disabled"
            @click="handleSubmit"
          >
            {{$t('message.send')}}
          </b-button>
        </div>
        
      </div>
    </ValidationObserver>
  </div>  
</template>

<script>
  import {ValidationProvider, ValidationObserver} from 'vee-validate';
  import { required} from '@validations';
  import { changeContractStatus } from '@/api/payments/contracts';
  export default {
    name: "Contract-PenaltyModal",
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    props: {
      id: {
        type: Number,
      }
    },
    data() {
      return {
        required,
        penaltyPrice: null,
        comment: '',
        form: {
          penaltyPrice: null,
          comment: '',
        },
        disabledItem: false,
        loading: false,
      }
    },
    methods: {
      handleClose() {
        this.$emit('handleClose')
      },
      handleSubmit() {
        const isValid = this.$refs.refFormObserver.validate()
        if(isValid) {
          this.disabled = true;
          this.loading = true;
        setTimeout(() => {
          this.disabled = false;
          this.loading = false;
        }, 1000)

          this.form.penaltyPrice = this.penaltyPrice;
          this.form.comment = this.comment;

          let data = {
            id: this.id,
            status_id: 4,
            penalty_price: this.form.penaltyPrice,
            comment: this.form.comment
          }

          changeContractStatus(data).then( () => {
            this.handleClose();
          })
        }
      },

    }
  }
</script>