<template>

  <div class="w-100">
    <b-card no-body class="card-statistics">
      <b-card-body class="statistics-body">
        <b-row>
          <b-col md="3">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" :variant="'info'">
                  <feather-icon size="24" :icon="'AlertCircleIcon'" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{$t('message.Contracts')}}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{allContracts}}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col md="3">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" :variant="'primary'">
                  <feather-icon size="24" :icon="'TrendingUpIcon'" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{$t('message.Actual_price')}}
                </h4>
                <b-card-text class="font-small-3 mb-0" v-if="actual_total">
                  <div class="w-100" v-for="(val, index) in actual_total" :key="index">
                    {{val.actualtotal | moneyDisplayFormat}} {{val.currency.name}}
                  </div>
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col md="3">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" :variant="'success'">
                  <feather-icon size="24" :icon="'DollarSignIcon'" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{$t('message.Market_price')}}
                </h4>
                <b-card-text class="font-small-3 mb-0" v-if="market_total">
                  <div class="w-100" v-for="(val, index) in market_total" :key="index">
                    {{val.markettotal | moneyDisplayFormat}} {{val.currency.name}}
                  </div>
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col md="3">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" :variant="'warning'">
                  <feather-icon size="24" :icon="'AlertCircleIcon'" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{$t('NotPaid')}}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{totalNotPaid}}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card>
      <b-row>
        <b-col md="3" v-if="checkRoleUser">
          <div class="wrapper">
            <p class="wrapper-title">
              {{$t('message.Branches')}}
            </p>
            <div class="wrapper-form">
              <v-select class="w-100" label="name" v-model="filter.branches" :options="branchesOptions"
                @input="getFilterContract"></v-select>
            </div>
          </div>
        </b-col>

        <b-col md="3">
          <div class="wrapper">
            <p class="wrapper-title">
              {{$t('message.Service')}}
            </p>
            <div class="wrapper-form">
              <v-select class="w-100" label="name" v-model="filter.service" :options="serviceOptions"
                @input="getFilterContract"></v-select>
            </div>
          </div>
        </b-col>

        <b-col md="3">
          <div class="wrapper">
            <p class="wrapper-title">
              {{$t('Status')}}
            </p>
            <div class="wrapper-form">
              <v-select class="w-100" label="name_uz" v-model="filter.status" :options="statusOptions"
                @input="getFilterContract"></v-select>
            </div>
          </div>
        </b-col>


        <b-col md="3">
          <div class="wrapper">
            <p class="wrapper-title">
              {{$t('message.tableHeader.date')}}
            </p>
            <div class="wrapper-form">
              <input type="date" v-model="filter.date" class="form-control" @input="getFilterContract">
            </div>
          </div>
        </b-col>


      </b-row>
    </b-card>

    <b-overlay :show="loadingShow" rounded="sm">
      <b-card>
        <b-table :fields="fields" :items="datas" responsive style="min-height: 25vh" :busy="busy">
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
          <template #cell(status)="{item}">
            <span :class="`text-${item.color}`">
              {{item.status ? item.status.name_ru : ''}}
            </span>
          </template>
          <template #cell(open)="row">
            <b-icon icon="chevron-right" @click="row.toggleDetails" class="cursor-pointer"></b-icon>
          </template>
          <template #cell(market_price)="{item}">
            {{item.market_price}} {{item.currency.display_name}}
          </template>
          <template #cell(debt)="{item}">
            <span :class="`text-${item.color}`">{{item.debt}}</span>
          </template>

          <template #cell(created_at)="{item}">
            {{item.created_at | getDate}}
          </template>
          <template #cell(action)="{item}">
            <b-dropdown variant="primary" size="sm">
              <template #button-content>
                <b-icon icon="gear-fill" aria-hidden="true"></b-icon> {{$t('Settings')}}
              </template>
              <b-dropdown-item class="w-100" @click="contractCheque(item)">
                <div class="w-100">
                  {{$t('Cheque')}}
                </div>
              </b-dropdown-item>
              <b-dropdown-item class="w-100" v-if="item.status.id !== 3" @click="changePaymentStatus(item)">
                <div class="w-100">
                  {{$t('message.Pay')}}
                </div>
              </b-dropdown-item>
              <b-dropdown-divider v-if="item.status.id !== 4"></b-dropdown-divider>
              <!--            <b-dropdown-item variant="danger" v-if="item.status.id !== 4" @click="handlePenaltyModalOpen(item.id)">-->
              <!--              {{$t('message.Cancel')}}-->
              <!--            </b-dropdown-item>-->
            </b-dropdown>
          </template>
          <template #row-details="row">
            <b-table :fields="paymentField" :items="row.item.payments">
              <template #cell(created_at)="{item}">
                {{item.created_at | getDate}}
              </template>
              <template #cell(action)="{item}">
                <b-dropdown variant="primary" size="sm">
                  <template #button-content>
                    <b-icon icon="gear-fill" aria-hidden="true"></b-icon> {{$t('Settings')}}
                  </template>
                  <b-dropdown-item @click="handlePenaltyModalOpen(item.id)">
                    <b-icon icon="x" aria-hidden="true"></b-icon>
                    {{$t('message.Cancel')}}
                  </b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item variant="danger" @click="handlePaymentDelete(item.id,row.index)">
                    <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                    {{$t('message.Delete')}}
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </template>
        </b-table>
      </b-card>
    </b-overlay>

    <b-pagination v-model="pagination.page" :total-rows="total" :per-page="pagination.pageSize" @input="fetchList">
    </b-pagination>

    <!-- fetchList  -->
    <b-modal hide-footer v-model="paymentModal">
      <ContractPayment :contractId="contractId" @closeModal="closeModal" />
    </b-modal>
    <b-modal v-model="contractqr" hide-footer>
      <ContractCheque :contract="activeContract" />
    </b-modal>

    <b-modal v-model="penaltyModalBool" no-close-on-backdrop hide-footer>
      <PenaltyModal :id="id" @handleClose="handlePenaltyModalClose" />
    </b-modal>
  </div>
</template>

<script>
  import {
    listTemplate
  } from '@/utils/mixins/listTemplate'
  import {
    changeContractStatus,
    getContracts
  } from '@/api/payments/contracts'
  import ContractPayment from '@/views/payments/components/ContractPayment'
  import moment from 'moment'
  import {
    deletePayment
  } from '@/api/payments'
  import ContractCheque from '@/views/payments/components/ContractCheque'
  import {
    getBranches
  } from '@/api/branchs/branchs'
  import {
    mapActions,
    mapGetters
  } from 'vuex';
  import {
    getCountractStatus
  } from '@/api/contract-statuses/contract-statuses'
  import {
    getServices
  } from '@/api/services/services';
  import PenaltyModal from '@/views/payments/components/PenaltyModal.vue'

  const actions = {
    get: getContracts
  }

  export default {
    name: 'Contracts',
    components: {
      ContractCheque,
      ContractPayment,
      PenaltyModal
    },
    filters: {
      getDate(val) {
        return moment(val).format('DD-MM-YYYY')
      }
    },
    data() {
      return {
        actions,
        fields: [{
            key: 'open',
            label: this.$t('message.Open')
          },
          {
            key: 'status',
            label: this.$t('Status')
          },
          {
            key: 'document_number',
            label: this.$t('DocumentNumber')
          },
          {
            key: 'service.name_ru',
            label: this.$t('message.Service')
          },
          {
            key: 'client.full_name',
            label: this.$t('message.Client')
          },
          {
            key: 'market_price',
            label: this.$t('message.Price')
          },
          {
            key: 'paid_price',
            label: this.$t('message.PaidPrice')
          },
          {
            key: 'debt',
            label: this.$t('data.Debt')
          },
          {
            key: 'branch.name',
            label: this.$t('message.Branch')
          },
          {
            key: 'created_at',
            label: this.$t('message.CreatedAt')
          },
          {
            key: "action",
            label: this.$t('message.Action')
          },
        ],
        paymentField: [{
            key: 'id',
            label: this.$t('message.ID')
          },
          {
            key: 'price',
            label: this.$t('message.Price')
          },
          {
            key: 'payment_type.name_ru',
            label: this.$t('data.payment_type')
          },
          {
            key: 'created_at',
            label: this.$t('message.CreatedAt')
          },
          {
            key: "action",
            label: this.$t('message.Action')
          },
        ],
        paymentModal: false,
        contractId: null,
        loadingShow: false,
        activeContract: null,
        contractqr: false,
        branchesOptions: [],
        serviceOptions: [],
        statusOptions: [],
        pagination: {
          page: 1,
          pageSize: 10,
        },
        filter: {
          service: null,
          branches: null,
          date: null,
          status: null,
          currencies: null,
        },
        filters: {
          service_id: null,
          branch_id: null,
          date: null,
          status_id: null,
          currency_id: null,
        },
        datas: [],
        total: 0,
        actual_total: null,
        market_total: null,
        totalNotPaid: 0,
        allContracts: 0,
        penaltyModalBool: false,
        id: null,
        busy: true,
      }
    },
    methods: {
      ...mapActions('settings', ['fetchCurrencies']),
      changePaymentStatus(id) {
        this.contractId = id;
        this.paymentModal = true;
      },
      fetchList() {
        getContracts({
          ...this.pagination,
          ...this.filters
        }).then(res => {
          this.datas = res.data.data.data;
          this.total = res.data.data.total
          this.actual_total = res.data.actual_total;
          this.market_total = res.data.market_total
          this.totalNotPaid = res.data.total_notpaid;
          this.allContracts = res.data.all_contracts;
          this.busy = false;
        })
      },
      cancelContract(id) {
        this.$swal({
          title: this.$t('areYouSure'),
          text: this.$t('wontReturn'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t('Change'),
          cancelButtonText: this.$t('Cancel'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
        }).then(result => {
          if (result.value) {
            changeContractStatus({
              id,
              status_id: 4
            }).then(res => {
              const index = this.datas.findIndex(item => item.id === id);
              this.datas[index].status = res.data.data;
              this.$swal({
                icon: 'success',
                title: this.$t('StatusChanged'),
                text: '',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              });
            });
          } else if (result.dismiss === 'cancel') {
            this.$swal({
              title: this.$t('Cancelled'),
              text: this.$t('yrDataSafe'),
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        })
      },
      closeModal() {
        this.paymentModal = false;
        this.fetchList();
      },
      contractCheque(item) {
        this.activeContract = item;
        this.contractqr = true
      },
      handlePaymentDelete(id, contract_index) {
        deletePayment({
          id
        }).then(res => {
          const index = this.datas[contract_index].payments.findIndex(item => item.id === id);
          this.datas[contract_index].payments.splice(index, 1)
        })
      },
      searchBranch(item) {
        if (item.length > 0) {
          getBranches({
            search: item
          }).then(res => {
            this.branchesOptions = res.data.data
          })
        }
      },
      getFilterContract() {
        this.filters.branch_id = this.filter.branches ? this.filter.branches.id : null;
        this.filters.service_id = this.filter.service ? this.filter.service.id : null;
        this.filters.status_id = this.filter.status ? this.filter.status.id : null;
        this.filters.date = this.filter.date ? moment(this.filter.date).format('YYYY-MM-DD') : null;
        this.filters.currency_id = this.filter.currencies ? this.filter.currencies.id : null;
        this.fetchList();
      },
      getBranchesOptions() {
        getBranches().then(res => {
          this.branchesOptions = res.data.data;
        })
      },
      getServiceOptions() {
        // this.serviceOptions
        getServices().then(res => {
          this.serviceOptions = res.data.data
        })
      },
      getStatusOptions() {
        // this.statusOptions
        getCountractStatus().then(res => {
          this.statusOptions = res.data.data
        })
      },
      handleCancel(id) {
        let data = {
          id: id,
          status_id: 4,
        }
        changeContractStatus(data).then(() => {
          this.fetchList();
        })
      },
      handlePenaltyModalClose() {
        this.id = null;
        this.penaltyModalBool = false;
        this.fetchList();
      },
      handlePenaltyModalOpen(id) {
        this.id = parseInt(id)
        this.penaltyModalBool = true;
      }
    },
    mounted() {
      this.fetchList()
      this.getBranchesOptions();
      this.getServiceOptions();
      this.getStatusOptions();
      this.fetchCurrencies();
    },
    computed: {
      ...mapGetters('settings', ['currencies']),
      ...mapGetters('auth', ['activeUser']),
      checkRoleUser() {
        if (this.activeUser !== null) {
          return this.activeUser.roles.some(item => item.name == 'SuperAdmin')
        }
        return false
      }
    }
  }
</script>