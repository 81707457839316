<template>
  <div class="w-100">
    <div id="qrCode" class="mb-5">
      <div class="w-100">
        <div class="text-center mb-2">
          <h3>
            Номер Договора: {{contract.document_number}}
          </h3>
        </div>
        <p>
          Ф.И.О : <strong>{{contract.client.full_name}}</strong>
        </p>
        <p>
          Услуга : <strong>{{contract.service.name}}</strong>
        </p>
        <p>
          Статус : <strong>{{contract.status.name_ru}}</strong>
        </p>
        <p>
          Филиал : <strong>{{contract.branch.name}}</strong>
        </p>
        <p>
          На оплату : <strong>{{contract.market_price}} {{contract.currency.name}}</strong>
        </p>
      </div>
      <div class="text-center">
        <img :src="qrCode" class="w-50" alt="">
<!--        <span v-html="qrCode"></span>-->
      </div>
    </div>
    <div class="w-100 text-center">
      <b-button variant="primary" @click="printCheque" :disable="disabled">Print</b-button>
    </div>
  </div>
</template>

<script>
import { getContractQR } from '@/api/payments'

export default {
  name: 'ContractCheque',
  props: {
    contract: {
      required:true,
    }
  },
  data() {
    return {
      qrCode: null,
      disabled: false,
    }
  },
  methods: {
    contractQR() {
      getContractQR({contract_id: this.contract.id}).then(res => {
        this.qrCode = res.data.data;
      })
    },
    printCheque() {
      this.disabled = true;
        setTimeout(() => {
          this.disabled = false;
        }, 1000)
      var mywindow = window.open('', 'PRINT', 'height=400,width=600');

      mywindow.document.write('<html><head>');
      mywindow.document.write('</head><body >');
      mywindow.document.write(document.getElementById('qrCode').innerHTML);
      mywindow.document.write('</body></html>');

      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/

      mywindow.print();
      // mywindow.close();
    }
  },
  watch: {
    contract:{
      immediate: true,
      handler(val) {
        this.contractQR()
      }
    }
  }
}
</script>

<style scoped>

</style>
