import request from '../../libs/axios'

export function getPayments(params) {
  return request({
    url: '/api/payments',
    method: 'get',
    params,
  })
}

export function deletePayment(id) {
  return request({
    url: `/api/payments/${id.id}`,
    method: 'delete',
  })
}

export function getSericeItemByService(params) {
  return request({
    url: '/api/get-service-item-by-service',
    method: 'get',
    params,
  })
}

export function getContractQR(params) {
  return request({
    url:'/api/get-contract-qr',
    method: 'get',
    params,
  })
}

export function updataPaymentStatus(data) {
  return request({
    url: '/api/change-payment-status',
    method: 'post',
    data
  })
}